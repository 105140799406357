import React from "react";
import Header from "../components/Header";
import HeaderTabs from "../components/HeaderTabs";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "../ContextApi";
import CardLoader from "../components/CardLoader.js";
import { useNavigate } from "react-router-dom";
import { NotokenFound } from "../Utils";
import { DataNotFoundIcon } from "../Assets/index";
import ClaimsFeatures from "../components/ClaimsFeatures.js";
import Wellness_Benefits from "./Wellness_Benefits.js";
import HeroSection from "../components/HeroSection.js";
import InsuranceProducts from "../components/InsuranceProducts.js";
import Claim_details_form from "../components/Claim_details_form.js";
import claim_submission_style from "../styles/claim-style.css";
import Claim_document_form from "./Claim_document_form.js";
import { INITIMATE_PATIENT_LIST_API , PINCODE_API } from "../Constants.js";
import HomeClaimKyc from "./Claim_kyc_home.js";
import CustomStepper from "../components/CustomStepper.js";
import ExistClaimModal from "./ExistClaimModal.js";
import { Button } from "react-bootstrap";
import { mainAPikey } from "../ContextApi";
import { toast , ToastContainer } from "react-toastify";



const Claim_submission = () => {
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const {
    activeElementId,
    requestOptions,
    setRequestOptions,
    tabapiData,
    policytypeidcontext,intimationId ,
    setIntimationId
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [intimateData, setIntimateData] = useState([]);
  const [appLinkToken , setappLinkToken] = useState(localStorage.getItem("appLinkToken"));
  const [ExistClaimModalShow, setExistClaimModalShow] = useState(false);
  const [claimExistData , setClaimExistData] = useState([]);
  const [existingData , setExistingData] = useState(null);
  const [memberName , setMemberName] = useState(null);




  // CLaim first Form--------------------------
  const [isDataSaved, setDataSaved] = useState(false);

  function handleTokenInResponse(response) {
    if (response.status === 401 || token === "") {
      NotokenFound(navigate);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });
        setToken(authorizationToken);

        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  useEffect(() => {
    if(intimationId === ""){
      setCurrentFormIndex(0)
    }
    else if (!token) {
      NotokenFound(navigate);
    }
  }, []);

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);

  const isDataLoading = false;

  useEffect(() => {
    const fetchPatientData = async () => {
      if (token && activeElementId) {
        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL  +
              `${INITIMATE_PATIENT_LIST_API}?policy_id=${activeElementId}&policy_type=${policytypeidcontext}`,
            requestOptions
          );

          const updatedResponse = await handleTokenInResponse(response);
          const res = await updatedResponse.json();
          if(res.status === 429){
            toast.error("Oops! You have reached maximum limit, please try again after sometime.")
          }
          if (res.success) {
            setIntimateData(res?.data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchPatientData();
  }, [activeElementId]);

  const steps = [
    { title: 'Claim Details', onClick: () => setCurrentFormIndex(0) },
    { title: 'Claim Documents', onClick: () => setCurrentFormIndex(1) },
    // { title: 'Kyc Documents', onClick: () => setCurrentFormIndex(2) },
  ];

  useEffect(() =>{
    window.scrollTo(40 , 40)
  },[currentFormIndex])


  return (
    <>
      <Header />
      <HeroSection />
      {tabapiData.length > 0 ? (
        <>
          <div className="claims-tabs-section-cover-wrapper">
            <div className="row">
              <div className="col-lg-4">
                <div className="claims-header-tabs">
                  
                  <ClaimsFeatures />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="policy-details-main-cover-wrapper">
                  {isDataLoading ? (
                    <CardLoader />
                  ) : (
                    <div className="">
                      <>
                        <div className="tab_content claim-tab_11">
                          <div className="claim-status-outer-cov claim-status-revised-wrapper">
                            <div id="accordion">
                              <div className="stepper_main_wrapper">
                              <CustomStepper
                                steps={steps}
                                activeStep={currentFormIndex}/>
                              </div>
                              {currentFormIndex === 0 ? (
                                <Claim_details_form 
                                      intimateData={intimateData} 
                                      isDataSaved={isDataSaved} 
                                      setDataSaved = {setDataSaved}
                                      setCurrentFormIndex={setCurrentFormIndex}
                                      currentFormIndex={currentFormIndex}
                                      setIntimationId={setIntimationId}
                                      setExistClaimModalShow={setExistClaimModalShow}
                                      setClaimExistData={setClaimExistData}
                                      setExistingData={setExistingData}
                                      existingData={existingData}
                                      setMemberName={setMemberName}
                                />
                              ) : currentFormIndex == 1 ? (
                                    <Claim_document_form  setCurrentFormIndex={setCurrentFormIndex} requestOptions={requestOptions} />
                                ) : (
                                  ""
                                )}
                                {/* <HomeClaimKyc setCurrentFormIndex={setCurrentFormIndex} /> */}
                              
                              
                            </div>
                          </div>
                        </div>
                        
                      </>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <ExistClaimModal
              claimExistData={claimExistData}
              show={ExistClaimModalShow}
              onHide={() => setExistClaimModalShow(false)}
              setExistingData={setExistingData}
              setExistClaimModalShow={setExistClaimModalShow}
              memberName={memberName}
            />
          </div>
          
          </>
      ) : (
        <div className="data-not-found-icon">
          <img src={DataNotFoundIcon} alt="data-not-found" />
        </div>
      )}
    </>
  );
};


export default Claim_submission;
