import React, { useState, useContext , useRef } from "react";
import HeaderTestTabs from "../components/HeaderTabsUnique";
import IpdForm from "../components/IpdForm";
import OpdForm from "../components/Opdform";
import Header from "../components/Header";
import { AppContext } from "../ContextApi";
import { useEffect } from "react";
import ClaimsFeatures from "../components/ClaimsFeatures";
import NoIntimateClaimFound from "../components/NoIntimateClaimFound";
import Wellness_Benefits from "./Wellness_Benefits";
import HeroSection from "../components/HeroSection";

const Intimate_my_claims = () => {
  const [activetab, setactivetab] = useState("IPD");
  const { intimateArray, GlobalintimateVisiblity , tabapiData  , featuresType} = useContext(AppContext);
  const [appLinkToken , setappLinkToken] = useState(localStorage.getItem("appLinkToken"))
  const HomeScrollRef = useRef(null);

  const intimatClaimVisiblity = tabapiData?.find((dumData) => dumData.policy_type_name == featuresType)?.intimate_claim_visibility
  
  function showComponent(selectedOption) {
    switch (selectedOption) {
      case intimatClaimVisiblity === 0:
        return <h5></h5>;
      case intimatClaimVisiblity === 1:
        return (
          <>
            <IPD_Tab activetab={activetab} setactivetab={setactivetab} />
            <OPD_Tab activetab={activetab} setactivetab={setactivetab} />
          </>
        );
      case intimatClaimVisiblity === 2:
        return <IPD_Tab activetab={activetab} setactivetab={setactivetab} />;
      case intimatClaimVisiblity === 3:
        return <OPD_Tab activetab={activetab} setactivetab={setactivetab} />;
      default:
        break;
    }
  }

  const selectedOption = intimateArray[GlobalintimateVisiblity];

  useEffect(() => {
    showComponent(selectedOption);
    console.log(intimatClaimVisiblity , "ksjkdhjshkdhkj")
  }, [GlobalintimateVisiblity]);

 

  return (
    <>
      <Header />
      <HeroSection scrollToSectionRef={HomeScrollRef} type={1} />
      <div className="claim-status-main-cover-wrapper">
        <div className="claim-status-header-cover">
          <div className="claims-tabs-section-cover-wrapper">
            <div className="row">
              <div className="col-lg-4">
                <div className="claims-header-tabs">
                  <ClaimsFeatures />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="claims-content-cover-wrapper">
                  <div className="intimate-claims-main-wrapper-cover">
                    <div className="container">
                      <div className="intimate-claims-header-cover">
                        <h3 className="text-center">Intimate my claims</h3>
                      </div>
                      <div className="intimate-claims-main-content-cover">
                        <div className="opd-ipd-tabs-cover d-flex justify-content-center mb-5">
                          {showComponent(selectedOption)}
                        </div>
                        <div className="intimate-claims-form-main-cover">
                          <div className="row">
                            <div className="col-lg-10 order-lg-0 order-1">
                              <div className="intimate-claims-form-box">
                                {activetab == "IPD" ? <IpdForm /> : <OpdForm />}
                              </div>
                            </div>
                            {/* <div className="col-lg-4 order-lg-1 order-0">
                              <div className="intimate-claims-main-banner-img">
                                <img src={intimate_claims_banner_img} alt />
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


const IPD_Tab = ({ activetab, setactivetab }) => {
  return (
    <div
      className={
        activetab == "IPD" ? "active-tab opd-ipd-tab-box" : "opd-ipd-tab-box"
      }
      onClick={() => setactivetab("IPD")}
    >
      <h3>IPD</h3>
    </div>
  );
};

const OPD_Tab = ({ activetab, setactivetab }) => {
  return (
    <div
      className={
        activetab == "OPD" ? "active-tab opd-ipd-tab-box" : "opd-ipd-tab-box"
      }
      onClick={() => setactivetab("OPD")}
    >
      <h3>OPD</h3>
    </div>
  );
};

export default Intimate_my_claims;
