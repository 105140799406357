import React,{useRef} from "react";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { NavLink } from "react-router-dom";
import "swiper/css";
import { loadingPackageBanner } from "../Assets";
import { IoIosArrowRoundForward } from "react-icons/io";


const Packages = ({ packagesdata, appLinkToken, ispackageLoading }) => {

  const bannerRef = useRef(null); 


  return (
    <div
      className="packages-main-cover-wrapper mt-0 mb-0"
      style={{ backgroundImage: `url(${packagesdata?.banner})` }}
    >
      {ispackageLoading ? (
        <div className="loading-package-cover-wrapper">
          <img src={loadingPackageBanner} alt="loading package" />
        </div>
      ) : (
        packagesdata &&
        packagesdata.length > 0 && (
          <>
            <h3 className="text-center mb-4">Active Package</h3>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-10">
                <Swiper spaceBetween={10} slidesPerView={1}>
                  {packagesdata.map((packageBox, inde) => (
                    <SwiperSlide
                      key={packageBox.id}
                      className="package-repo-data"
                    >
                 
                      <NavLink
                        className="package-box-link-cover"
                        to={`https://app.medibhai.com/packagedetailbox?id=${packageBox.id}&token=${appLinkToken}&href=${window.location.href}`}
                      >
                        <div
                          className="home-section-banner"
                          style={{
                            backgroundImage: packageBox?.banner ? `url(${packageBox.banner})` : "none",
                          }}
                        >
                          <div className="container">
                            <div className="home-section-main-banner">
                              <div className="row">
                                {/* <div className="col-lg-4 col-8"></div> */}
                                <div className="col-lg-12 col-4 pe-0">
                                  <div className="banner-right-side">
                                    {/* <div className="package-title">
                                      <p>{packageBox?.name}</p>
                                    </div>
                                    <div className="package-date">
                                      <p>
                                        Package Valid Till{" "}
                                        <span>{packageBox?.expiring_on}</span>
                                      </p>
                                      <button>
                                        <IoIosArrowRoundForward />
                                      </button>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default Packages;
