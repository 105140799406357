import { useState, useEffect, useContext , useRef } from "react";
import Header from "../components/Header";
import CashlessModal from "../components/CashlessModal";
import { AppContext } from "../ContextApi";
import ErrorDataComponent from "../components/ErrorData";
import HeaderTabs from "../components/HeaderTabs";
import CardLoader from "../components/CardLoader";
import NoDataFound from "../components/NoDataFound";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NotokenFound, handleTokenInResponse } from "../Utils";
import ClaimsFeatures from "../components/ClaimsFeatures";
import Wellness_Benefits from "./Wellness_Benefits";
import HeroSection from "../components/HeroSection";
import { mainAPikey } from "../ContextApi";

import {
  download_btn_icon,
  membercardlogo,
  memberlogo,
  insurer_dummy,
  tpa_dummy,
} from "../Assets/index";

const Members_covered = () => {
  const [show, setShow] = useState(false);
  const [dataMsg, setdataMsg] = useState("");
  const [MembercoveredData, setMembercoveredData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const HomeScrollRef = useRef(null);
  const [isErrorData, setisErrorData] = useState(false);
  const [EcardpdfData, setEcardpdfData] = useState();
  const [Errorfields, setErrorFields] = useState(null);
  const [insurerLogoPath, setInsurerLogoPath] = useState("");
  const [tpalogoPath, setTpalogoPath] = useState("");
  const {
    activeElementId,
    requestOptions,
    setRequestOptions,
    policytypeidcontext,
  } = useContext(AppContext);
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const [appLinkToken , setappLinkToken] = useState(localStorage.getItem("appLinkToken"))
  const navigate = useNavigate();

  async function handleTokenInResponse(response) {
    if (response.status === 401) {
      NotokenFound(navigate);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        setToken(response.headers.get("Authorization"));
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });

        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  useEffect(() => {
    if (activeElementId && token) {
      setisLoading(true);

      async function GetMemberCoveredData() {
        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL  +
              `/employee/members-covered-list?policy_type=${policytypeidcontext}`,
            requestOptions
          );
          const tokenizedResponse = await handleTokenInResponse(response);
          const res = await tokenizedResponse.json();
          if (response.status === 429) {
            toast.error("Oops! You have reached maximum limit, please try again after sometime.");
          }
          if (res.success) {
            setMembercoveredData(res.data.members);
            setEcardpdfData(res.data.ecard);
            setInsurerLogoPath(res.data.INSURER_LOGO_PATH);
            setTpalogoPath(res.data.TPA_LOGO_PATH);
            setisLoading(false);
          } else {
            setMembercoveredData(res.data);
            setdataMsg(res.message);
            setisLoading(false);
            setErrorFields(res.errors_fields);
            // console.log(res.errors_fields)
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setisLoading(false);
        }
      }

      GetMemberCoveredData();
    }
  }, [activeElementId, token]);

  useEffect(() => {
    if (!token) {
      NotokenFound(navigate);
    }
  }, []);

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);

  const handleClose = () => setShow(false);

  const openModal = () => {
    setShow(true);
  };
 
  return (
    <>
      <div className="member_main_outer_cover">
          <Header />
          <HeroSection scrollToSectionRef={HomeScrollRef}/>
          
            <div className="claims-tabs-section-cover-wrapper">
              <div className="row">
                <div className="col-lg-4">
                  <div className="claims-header-tabs">
                    
                    <ClaimsFeatures />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="claims-content-cover-wrapper">
                    {isLoading ? (
                      <CardLoader />
                    ) : (
                      <div className="members-covered-main-cover-wrapper">
                        <div className="members-main-cover-wrapper">
                          <div className="members-download-card">
                            {EcardpdfData && EcardpdfData.length === 0 ? (
                              ""
                            ) : (
                              <button class="Btn" onClick={() => openModal()}>
                                <div class="sign">
                                  <img src={download_btn_icon} alt="Download" />
                                </div>
                                <div class="text">Download Cashless Card</div>
                              </button>
                            )}
                          </div>
                          <div className="members-covered-cards-wrapper mb-5">
                            <div className="row">
                              {MembercoveredData &&
                              MembercoveredData.length > 0 ? (
                                MembercoveredData.map((memberData, ind) => (
                                  <>
                                    <div className="col-lg-5" key={ind}>
                                      <div className="members-details-card">
                                        <div className="members-card-header d-flex justify-content-between align-items-center">
                                          <div className="member-card-header-details">
                                            <h4>{memberData?.member_name}</h4>
                                            <p className="p-0 m-0">
                                              {memberData.member_id}
                                            </p>
                                          </div>
                                          <div className="member-card-logo">
                                            {memberData.insurer_logo !==
                                              "NULL" &&
                                              memberData.insurer_logo !==
                                                null && (
                                                <img
                                                  src={`${insurerLogoPath}${memberData.insurer_logo}`}
                                                  alt="TPA Logo"
                                                />
                                              )}
                                          </div>
                                        </div>
                                        <div className="member-card-details">
                                          <div className="members-card-content d-flex justify-content-between">
                                            <div className="members-date-text">
                                              <li>
                                                DOB :
                                                <span>
                                                  {" "}
                                                  {memberData.member_dob}
                                                </span>
                                              </li>
                                              <li>
                                                Relation:
                                                <span>
                                                  {" "}
                                                  {memberData.member_relation}
                                                </span>
                                              </li>
                                              <li>
                                                Age:{" "}
                                                <span>
                                                  {" "}
                                                  {memberData.member_age}
                                                </span>
                                              </li>
                                            </div>
                                            <div className="members-tpa-logo">
                                              {memberData.tpa_logo !== "" &&
                                                memberData.tpa_logo !==
                                                  null && (
                                                  <img
                                                    src={`${tpalogoPath}${memberData.tpa_logo}`}
                                                    alt="TPA Logo"
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                  </>
                                ))
                              ) : (
                                <NoDataFound
                                  msg={
                                    Errorfields
                                      ? Errorfields.policy_type[0]
                                      : dataMsg
                                  }
                                />
                              )}
                              
                            </div>

                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          <CashlessModal
            data={EcardpdfData}
            show={show}
            handleClose={handleClose}
          />
          <ToastContainer
    position="top-center"
/>
        </div>
    </>
  );
};

export default Members_covered;
