import React, { createContext, useState, useEffect } from "react";

export const AppContext = createContext();

export let mainAPikey 

const ContextProvider = ({ children }) => {
  const [activeTab, setactiveTab] = useState("active");
  const [activeElementId, setActiveElementId] = useState(null);
  const [isLoggedIn , setIsLoggedIn]  = useState(false);
  const [isScrolledBtnActive , setScrollBtnActive] = useState(null);
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const [isLoading, setisLoading] = useState(true);
  const [policytypeidcontext, setpolicytypeidcontext] = useState(null);
  const [callPolicyDetailApi , setPolicyDetailApi]  = useState(false);
  const [featuresType , setFeaturesType] = useState('GMC')
  const [istabLoading, setTabLoading] = useState(true);
  const [tabdata, setTabdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tabapiData, setTabapiData] = useState([]);
  const [constantsApiData, setConstantsApiData] = useState([]);
  const [imagesPath, setImagesPath] = useState("");
  const [TabsimgArray, setTabsimgArray] = useState([]);
  const [GlobalintimateVisiblity, setGlobalintimateVisiblity] = useState(null);
  const [intimateArray, setIntimateArray] = useState([]);
  const [defaultactiveId, setDefaultactiveId] = useState(null);
  const [defaultPolicytypeId , setdefaultPolicyTypeId] = useState(null)
  const [employeeUserData , setEmployeeUserData] = useState([]);
  const [intimationId , setIntimationId] = useState(null);
  const [claimSubmissionVisiblity , setClaimSubmissionVisiblity] = useState(1)
  const [apiKey  , setApiKey] = useState(null)




  function base64Encode(data) {
    return btoa(JSON.stringify(data)); // Encode JSON string in base64
  }

  const [requestOptions, setRequestOptions] = useState({
    method: "GET",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("authorizationToken")}`,
    },
  })
  const [AuthToken , setAuthToken] = useState(null)


async  function handleTokenInResponse(response) {
    if (response.headers.has("Authorization")) {
      const authorizationToken = response.headers.get("Authorization");
      console.log("FOund")
      localStorage.setItem("authorizationToken", authorizationToken);
      setRequestOptions((prevOptions) => ({
        ...prevOptions,
        headers: {
          ...prevOptions.headers,
          Authorization: authorizationToken,
        },
      }));
      const updatedResponse = new Response(response.body, {
        ...response,
        headers: {
          ...response.headers,
          Authorization: authorizationToken,
        },
      });
      setToken(authorizationToken)
      setAuthToken(authorizationToken)

      return updatedResponse;
    } else {
      console.log('Token Not Found');
      return response;
    }
  }

  
  useEffect(() => {
    if (token && mainAPikey) {
      setTabLoading(true);
      requestOptions.method = 'GET'
      async function fetchData() {
        try {
          const response = await fetch( process.env.REACT_APP_API_URL   + "/employee/get-policy-tabs", requestOptions);
          const updatedResponse = await handleTokenInResponse(response);
          const res = await updatedResponse.json();
          if (res.success) {
            // console.log(res?.data)
            setTabapiData(res.data);
            setTabdata(res.data);
            setTabLoading(false);
            setActiveElementId(res.data[0]?.policy_id);
            setdefaultPolicyTypeId(res.data[0]?.policy_type_id);
            setpolicytypeidcontext(res.data[0]?.policy_type_id);
            setGlobalintimateVisiblity(res.data[0]?.intimate_claim_visibility);
            setClaimSubmissionVisiblity(res.data[0]?.claim_submission_visibility);
            setDefaultactiveId(res.data[0]?.policy_id);
            setPolicyDetailApi(true);
          } else {
            setTabLoading(false);
            setPolicyDetailApi(false);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setTabLoading(false);
        }
      }
      // Call the async function to fetch data
      fetchData();
    }
  }, [token , mainAPikey]);
  
  

  useEffect(() => {
    fetch( process.env.REACT_APP_API_URL  +`/constants`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setConstantsApiData(res.data);
        // console.log(res.data)
        setTabsimgArray(res.data.POLICY_TYPE_LOGO);
        setImagesPath(res.data.images_base_path);
        setIntimateArray(res.data.INTIMATE_CLAIM_VISIBILITY);
        setApiKey(atob(res?.data?.PREFIX_PARAM))
        mainAPikey = (atob(res?.data?.PREFIX_PARAM))
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
      });
  }, []);

  return (
    <AppContext.Provider 
      value={{
        activeTab,
        currentPage,
        setCurrentPage,
        tabdata,
        setTabdata,
        setactiveTab,
        activeElementId,
        setActiveElementId,
        token,
        setToken,
        isLoading,
        setisLoading,
        policytypeidcontext,
        setpolicytypeidcontext,
        istabLoading,
        setTabLoading,
        tabapiData,
        constantsApiData,
        setGlobalintimateVisiblity,
        GlobalintimateVisiblity,
        intimateArray,
        setDefaultactiveId,
        defaultactiveId,
        requestOptions,
        setRequestOptions ,isLoggedIn , setIsLoggedIn ,
        defaultPolicytypeId , setdefaultPolicyTypeId ,
        AuthToken , setAuthToken , featuresType , setFeaturesType,
        setEmployeeUserData , employeeUserData,
        intimationId ,setIntimationId , apiKey , mainAPikey , setScrollBtnActive ,isScrolledBtnActive
      }}
    >
      {children}
    </AppContext.Provider>
  );
};


export default ContextProvider;
